import Vue from 'vue'
import axios from 'axios'
// import router from '@/router'
// import qs from 'qs'
// import merge from 'lodash/merge'
// import { clearLoginInfo } from '@/utils'
// import { Message } from 'element-ui'


//放到单独的文件，便于维护
const request  = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  baseURL:process.env.VUE_APP_BASE_URL,  //请求的基本路径process.env.VUE_APP_BASE_URL
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})
 // 'Content-Type': 'application/x-www-form-urlencoded/json; charset=utf-8'

//请求拦截器
request.interceptors.request.use(
    // 在发送请求之前做些什么,所有请求都会经过这里
    // config是当前请求相关的配置信息对象
    //config是可以修改的
    //然后我们就可以在请求出去时定制统一的业务功能
    //例如 设置统一的 ；token

)
//响应拦截器


//导出请求方法
export default request

//谁使用谁就要加载request模块
// import request from 'request.js'
// request.xxx
// request({
//     method: '',
//     url: ''
// })




