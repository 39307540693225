import request from '../utils/request.js'

//搜索品牌
export function ppSearch (query) {
  return request({
    url: '/website/siteSearch/brandList',
    method: 'get',
    params: query
  })
}


//搜索行业
export function hySearch (query) {
  return request({
    url: '/website/siteSearch/queryIndustryIdByIndustryName',
    method: 'get',
    params: query
  })
}







