<template>
  
<div class="i-footer-wrap">
	<div class="i-footer wrap">
   	
		<div class="i-footer-top">
			<div class="i-footer-top-item">
				<a href="/research"><i></i>品牌之家研究院</a>
			</div>
			<div class="i-footer-top-item">
				<a href="#"><i></i>生活TOP榜数据源</a>
			</div>
			<div class="i-footer-top-item">
				<a href="#"><i></i>友情链接</a>
			</div>
		</div><!-- i-footer-top end -->
		
		<div class="i-footer-nav">
			<a href="/about">关于我们</a>
			<!-- <a href="#">网站合作</a> -->
			<a href="/contact">联系方式</a>
			<a href="/statement">免费声明</a>
		</div>
		
		<div class="i-footer-info">
			<div class="i-footer-info-text">
				<img src="../assets/images/icon-foot-i_03.png" alt="" class="i-footer-info-text-l"/>
				<div class="i-footer-info-text-r">
					<p>网站为信息储存空间，数据主要来自用户提交，不代表网站观点，仅供学习与参考，本站无法证实其真实性。如因用户非法使用引起纠纷，一切法律后果由使用者承担。如无意侵犯您的知识产权，请来函告知，并附上权利证书，本网站审核后立即删除侵权内容。</p>
				</div>
			</div>
			
			<div class="i-footer-copy">
				Copy蜀ICP备2024094898号
			</div>
			
		</div>
   	
   	
   </div>
   
   
</div>

</template>

<script>
	export default {
		data() {
			return {
			    
			}
		},
		computed: {
		
		},
		methods:{
			
		}
	}
</script>

<style scoped>

.i-footer-wrap{
	background: #262626;
	padding-bottom: 40px;
}

.i-footer-top{
	height: 40px;
	padding: 25px 50px;
	border-bottom: 1px solid #3c3c3d;
	display: flex;
	justify-content: space-between;
}

.i-footer-top-item a{
	color: #fff;
	font-size: 16px;
	line-height: 40px;
}

.i-footer-top-item i{
	display: inline-block;
	width: 10px;
	height: 20px;
	background-image: linear-gradient(to bottom, #e23b3c, #b41b1b);
	border-radius: 8px;
	float: left;
	margin: 10px 10px 0 0;
}

.i-footer-nav{
	text-align: center;
	margin: 30px 0;
}

.i-footer-nav a{
	color: #fff;
	font-size: 14px;
	margin: 0 20px;
	line-height: 30px;
}

.i-footer-top-item a:hover,
.i-footer-nav a:hover{
	text-decoration: underline;
}

.i-footer-info-text{
	display: flex;
}

.i-footer-info-text-l{
	width: 20px;
	height: 20px;
	margin-right: 10px;
	margin-top: 3px;
}

.i-footer-info-text-r{
	line-height: 26px;
	color: #bdc1c5;
	font-size: 14px;
}

.i-footer-copy{
	padding: 5px 30px;
	color: #fff;
	font-size: 14px;
	line-height: 26px;
}



</style>
